import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SeverityLevelDTO } from '../dto/severity-level.dto';

@Injectable({
  providedIn: 'root',
})
export class SeverityLevelService {
  private apiUrl = environment.apiUrl; // Use the apiUrl from environment

  constructor(private http: HttpClient) {}

  getSeverityLevels(
    sortField: string = 'title_en',
    sortOrder: string = 'asc',
    searchQuery?: string
  ): Observable<SeverityLevelDTO[]> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder);

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    return this.http.get<SeverityLevelDTO[]>(this.apiUrl + '/severity-levels', {
      params,
    });
  }

  addSeverityLevel(
    severityLevel: SeverityLevelDTO
  ): Observable<SeverityLevelDTO> {
    return this.http.post<SeverityLevelDTO>(this.apiUrl, severityLevel);
  }
}
